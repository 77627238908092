@font-face {
    font-family: 'dead_by_dawn_usregular';
    src: url('../fonts/dead_by_dawn-webfont.woff2') format('woff2'),
         url('../fonts/dead_by_dawn-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.splat1 {
    height: 60vh;
    position: fixed;
    z-index: -9;
    left: 20vw;
    top: 5vh;
  }
  
  .splat2 {
    height: 60vh;
    position: fixed;
    z-index: -9;
    right: 10vw;
    bottom: 5vh;
  }
  
  .transition-text {
    font-family: 'dead_by_dawn_usregular';
    font-weight: normal;
    color: white;
    font-size: clamp(80pt, 50vw, 300pt);

    margin-top: 20vh;
    height: 60vh;
    cursor: pointer;
    display: inline-block;
    margin-left: -50px;
    width: 100%;
  }

.backgroundLayer {
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
  mix-blend-mode: lighten;
  /* position: fixed; */
}

.prod-title {
  font-family: 'dead_by_dawn_usregular';
  font-weight: normal;
  color: white;
  font-size: clamp(25pt, 3.5vw, 70pt);
  text-align: center;
}

.prod-img-div {
  float: left;
  height: 40vh;
  border: 2px solid white;
  border-radius: 20px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: white;
}

.Prod-font-size {
  font-size: clamp(15pt, 3vw, 20pt);
}

.Prod-cart {
  font-family: 'dead_by_dawn_usregular';
  font-weight: normal;
  color: white;
  font-size: clamp(20pt, 3.5vw, 70pt);
  text-align: center;
}

.Prod-cart-atc {
  font-family: 'dead_by_dawn_usregular';
  font-weight: normal;
  color: white;
  font-size: clamp(20pt, 3.5vw, 70pt);
  text-align: center;
}

.Prod-cart-atc:hover {
  color: red
}

.Prod-price-font {
  font-weight: 500;
  color: white;
  font-size: clamp(20pt, 3vw, 30pt)
}

.fingerprint-variant {
  mix-blend-mode: lighten;
  position: absolute;
  height: 70px;
  margin-left: -30px;
  margin-top: -10px;
}

.splat1-prod {
  width: 20vw;
  z-index: -9;
  margin-top: 15vh;
  margin-left: -37vw;
  position: absolute;
}

.splat2-prod {
  width: 20vw;
  z-index: -9;
  margin-top: -5vh;
  margin-left: -10vw;
  transform: rotate(90deg);
  position: absolute;
}