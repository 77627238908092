body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow-x: "none"
}

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: green; */
  position: fixed;
  z-index: -9;
  mix-blend-mode: lighten;
  background-blend-mode: lighten;
  top: 0;
  left: 0;
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 100vh;
  padding-bottom: 100vh;
  vertical-align: middle;
  overflow: hidden;
}

.svg-content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.layer {
  position: absolute;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;
}
 
.App__view-cart-wrapper {
  float: right;
}

.App__view-cart-wrapper2 {
 position: absolute;
 top: 30px;
 margin-left: -75px;
}

.App__view-cart {
 border: none;
 background: none;
 cursor: pointer;
 color: grey;
}

/* CART
   * ============================== */
   .Cart {
    position: fixed;
    top: 5vh;
    right: 5vw;
    height: 90vh;
    width: 90vw;

    /* outline: 2pt solid red; */
  
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    transform: translateX(120%);
    transition: transform 0.5s ease-in-out;
    z-index: 999999999;

    backdrop-filter: blur(10px);
    background-color: rgb(255, 0, 0, 0.5);
  }

  .cart-header {
    font-family: 'dead_by_dawn_usregular';
    font-weight: normal;
    font-style: normal;
    color: red;
    font-size: 50pt;
  }
  
  .Cart--open {
    transform: translateX(0);
  }
  
  .Cart__close {
    position: absolute;
    right: 9px;
    top: 8px;
    font-size: 35px;
    color: #999;
    border: none;
    background: transparent;
    transition: transform 100ms ease;
    cursor: pointer;
  }
  
  .Cart__header {
    padding: 20px;
    flex: 0 0 auto;
    display: inline-block;
    font-weight: 800;
    color: black;
    background-color: aqua;
  }
  
  .Cart__line-items {
    flex: 1 0 auto;
    margin: 0;
    padding: 20px;
    height: 100%;
    margin-right: 10px;
  }
  
  .Cart__footer {
    padding: 0px 20px 0px 20px;
    flex: 0 0 auto;
  }
  
  .Cart__checkout {
    margin-top: 20px;
    display: block;
    width: 100%;
    font-weight: 700;

    font-family: 'dead_by_dawn_usregular';
    font-weight: normal;
    color: white;
    font-size: clamp(50pt, 7vw, 100pt);
    cursor: pointer;
    text-align: center;
  }

  .Cart__checkout:hover {
    color: red;
  }
  
  .Cart-info {
    padding: 15px 20px 10px;
  }
  
  .Cart-info__total {
    float: left;
    text-transform: uppercase;
  }
  
  .Cart-info__small {
    font-size: 15px;
    color: white;
    font-family: sans-serif;
    font-weight: 800;
  }
  
  .Cart-info__pricing {
    float: right;
  }
  
  .pricing {
    font-size: 15px;
    color: white;
    font-family: sans-serif;
    font-weight: 800;
  }
  
  /* LINE ITEMS
    * ============================== */
  .Line-item {
    margin-bottom: 20px;
    backface-visibility: visible;
    min-height: 65px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    overflow-y: scroll;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;

    width: 100%;
    padding: 0px 20px 20px 20px;
    z-index: 999;
  }
  
  .Line-item__img {
    width: 25%;
    height: 100px;
    border-radius: 3px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: black;
    border-radius: 20px;
    border: 3px solid white;

    float: left;
    position: relative;
  }
  
  .Line-item__content { 
    width: 75%;
    float: left;
    top: 0;
    position: relative;
    padding-left: 10px;
    height: 100%;
  }
  
  .Line-item__content-row {
    display: inline-block;
    width: 100%;
  }
  
  .Line-item__variant-title {
    float: right;
    font-weight: bold;
    font-size: clamp(10pt, 2vw, 15pt);
    /* line-height: 17px; */
    color: white;
    font-family: sans-serif;
    font-weight: 800;
  }
  
  .Line-item__title {
    color: red;
    font-family: 'dead_by_dawn_usregular';
    font-weight: normal;
    color: white;
    font-size: clamp(20pt, 3vw, 25pt);
  }
  
  .Line-item__quantity-container {
    border: 3px solid white;
    float: left;
    border-radius: 20px;
    margin-left: 10px;
    bottom: 0;
    position: absolute;
  }
  
  .Line-item__quantity-update {
    color: white;
    display: block;
    float: left;
    height: 21px;
    line-height: 16px;
    font-family: sans-serif;
    font-weight: 800;
    width: 25px;
    padding: 0;
    border: none;
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
  }
  
  .Line-item__quantity-update-form {
    display: inline;
  }
  
  .Line-item__quantity {
    color: white;
    width: 38px;
    height: 21px;
    line-height: 23px;
    font-size: 15px;
    border: none;
    text-align: center;
    -moz-appearance: textfield;
    background: transparent;
    font-family: sans-serif;
    font-weight: 800;
    display: block;
    float: left;
    padding: 0;
    border-radius: 0;
  }

  .Line-item__price {
    float: right;
    right: 10px;
    border: 0;
    background: 0;
    font-size: clamp(12pt, 2vw, 20pt);
    font-weight: 600;
    color: white;
    padding-right: 30px;

    bottom: 0;
    position: absolute;
  }
  
  .Line-item__remove {
    float: right;
    right: 0;
    border: 0;
    background: 0;
    opacity: 0.5;
    position: absolute;
    bottom: 2.5px;
    z-index: 99;
  }
  
  .Line-item__remove:hover {
    opacity: 1;
    cursor: pointer;
  }