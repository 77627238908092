@font-face {
    font-family: 'dead_by_dawn_usregular';
    src: url('../../fonts/dead_by_dawn-webfont.woff2') format('woff2'),
         url('../../fonts/dead_by_dawn-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.nav-container {
    width: 96vw;
    position: fixed;
    z-index: 99;
    display: block;
    height: 100px;
    margin: 2vw 0 0 2vw;
    top: 0;
}

.nav-container-mobile {
    width: 100vw;
    /* outline: 2pt solid red; */
    position: fixed;
    backdrop-filter: blur(10px);
    z-index: 99;
    background: rgb(252, 3, 3, 0.2);
    display: block;
    height: 100vh;
    top: 0;
    left: 0;
}

.header-image {
    height: 100px;
    float: left;
    cursor: pointer;
}

.mainMenu-ul {
    display: flex;
    align-items: stretch; /* Default */
    justify-content: space-between;
    width: 40%;
    margin: 0 50px 0 0;
    padding: 0;
    float: right;
    height: 100%;
}
  
.mainMenu-li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
}

.navbar-text {
    font-family: 'dead_by_dawn_usregular';
    font-weight: normal;
    font-style: normal;
    font-size: clamp(10pt, 4vw, 35pt);
    cursor: pointer;
    color: red;
    margin-top: 0;
    line-height: 70px;
    /* line-height: 0; */

    /* background: green; */
}

.navbar-text:hover {
    color: white;
}

.fingerprint {
    mix-blend-mode: lighten;
    position: absolute;
    height: 70px;
    margin-left: -70pt;
    top: 10px
}

.menu--right { 
  right: 0; 
  width: 100vw; 
  height: 100vh;
  text-align: center;
}

.menu-list { 
  list-style: none;
}
    
.menu-list-item { 
  font-family: dead_by_dawn_usregular;
  font-weight: 800;
  font-style: normal;
  color: white;
  list-style: none; 
}
  
/* .menu-list-item--right { 
margin: 0 10px; 
} */

.menu a { 
  text-decoration: none; 
  color: white;
}
    
.menu a:hover, 
.menu a:focus { 
  color: black; 
  text-decoration: none;
}

.li {
  color: white;
}

.menu-text-h1 {
  color: white;
  font-size: 50pt;
}

.menu-text-h1:hover {
  color: red;
  text-decoration: none;
}